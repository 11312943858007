//
// Page Title(used by Dark Header layout only)
//

// Desktop mode
@include media-breakpoint-up(lg) {
  .page-heading {
    letter-spacing: -0.02em;
    font-size: 26px !important;
    font-weight: 600 !important;
    line-height: 42px;
    text-align: left;
  }

  [data-kt-app-layout="dark-header"] {
        .app-header {
            .page-heading {
                color: $white !important;
            }
        }
    }
}
